import React from "react";
import DevelopmentPartners from "../DevelopmentPartners/DevelopmentPartners";

function MultiSectionProductDev() {
  return (
    <div className="section section--bg-dot-curve">
      <DevelopmentPartners />
    </div>
  );
}

export default MultiSectionProductDev;
