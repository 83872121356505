import React from "react";
import Layout from "../components/Layout";
import SolutionsHelmet from "../components/SolutionsHelmet/SolutionsHelmet";
import LearnAboutUs from "../components/Solutions/LearnAboutUs/LearnAboutUs";
import DevStartups from "../components/Solutions/Dev-Startups/Dev-Startups";
import MultiSectionProductDev from "../components/Solutions/MultiSectionProductDev/MultiSectionProductDev";
import OurPerspectives from "../components/Solutions/OurPerspectives/OurPerspectives";
// import OurPerspectives from "../components/Solutions/OurPerspectives_product-development/OurPerspectives";
import TechProcess from "../components/Solutions/TechProcess/TechProcess";
import { Helmet } from "react-helmet";
function ProductDevelopmentStartups() {
  const scrollTop = () => {
    window.scroll(0, 1);
  };
  return (
    <Layout><Helmet>
    <meta charSet="utf-8" />
       <title>
   Hashout Technologies -   Product Development
      </title>
       <meta
         name="description"
         content="Experienced, stable team that can augment your engineering velocity."
       ></meta></Helmet>
      <SolutionsHelmet />
      <DevStartups />
      <MultiSectionProductDev />
      <TechProcess />
      <OurPerspectives />
      <LearnAboutUs page="product-development"/>
      <a className="go-to-top" href="javascript:void(0);" onClick={scrollTop}>
        <img
          className="go-to-top__img"
          src="../../solutions/go-to-top.png"
          alt="Go to top"
        />
      </a>
    </Layout>
  );
}
export default ProductDevelopmentStartups;
