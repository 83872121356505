import React from "react";

function DevelopmentPartners() {
  return (
    <section className="section section--technologies development-partners section--dots-bg --section-medium-tb-padding">
      <div className="container">
        <div className="container__row">
          <div className="container__col-xs-12">
            <h2 className="container__heading">We are development partners for</h2>
          </div>
          <div className="container__col-md-4">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/solutions/development-partners/weavr.png"
                alt="Weavr.ai"
              />
              <h5 className="card__title">Weavr.ai:</h5>
              <div className="card__description">
                A silicon valley based startup empowering tech teams to build
                better products, learn new skills, and create better ecosystems
                with AI-driven insights, personalized mentors, and a curated
                community.
              </div>
            </div>
          </div>
          <div className="container__col-md-4">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/solutions/development-partners/story-trails.png"
                alt="Storytrails"
              />
              <h5 className="card__title">Storytrails</h5>
              <div className="card__description">
                A new age tour operator offering curated walking tours, food
                trails, and experiences with cutting-edge virtual apps.
              </div>
            </div>
          </div>
          <div className="container__col-md-4">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/solutions/development-partners/ollmoo.png"
                alt="OLLMOO"
              />
              <h5 className="card__title">OLLMOO</h5>
              <div className="card__description">
                A global community of young women that brings undiscovered
                talent to light and advances women leaders.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DevelopmentPartners;
