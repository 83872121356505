import React from "react";

function TechProcess() {
  return (
    <section className="section section--technologies-processes --bg-gray-lightest  --section-normal-tb-padding">
      <div className="container">
        <div className="container__row">
          <div className="container__col-xs-12">
            <h2 className="container__heading">Technologies & processes we use</h2>
          </div>

          <div className="container__row">
            <div className="container__col-xs-12">
              <div className="card">
                <h5 className="card__title --flex-center">CMS</h5>
                <div className="card__content --flex-center">
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/aem.png"
                    alt="aem"
                  />
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/contentful.png"
                    alt="contentful"
                  />
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/content-stack.png"
                    alt="content-stack"
                  />
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/sales-force.png"
                    alt="sales-force"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container__row">
            <div className="container__col-xs-12">
              <div className="card">
                <h5 className="card__title --flex-center">CODING</h5>
                <div className="card__content --flex-center">
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/java.png"
                    alt="java"
                  />
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/python.png"
                    alt="python"
                  />
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/rails.png"
                    alt="rails"
                  />
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/php.png"
                    alt="php"
                  />
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/html-js-css.png"
                    alt="html-js-css"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container__row">
            <div className="container__col-md-6 container__col-xs-12">
              <div className="card">
                <h5 className="card__title --flex-center">Front End</h5>
                <div className="card__content --flex-center">
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/react.png"
                    alt="react"
                  />
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/angular.png"
                    alt="angular"
                  />
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/vue.png"
                    alt="vue"
                  />
                </div>
              </div>
            </div>
            <div className="container__col-md-6 container__col-xs-12">
              <div className="card">
                <h5 className="card__title --flex-center">Cloud</h5>
                <div className="card__content --flex-center">
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/aws.png"
                    alt="aws"
                  />
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/google-cloud-platform.png"
                    alt="google-cloud-platform"
                  />
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/azure.png"
                    alt="azure"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container__row">
            <div className="container__col-md-6 container__col-xs-12">
              <div className="card">
                <h5 className="card__title --flex-center">Mobile Development</h5>
                <div className="card__content --flex-center">
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/react-native.png"
                    alt="react-native"
                  />
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/flutter.png"
                    alt="flutter"
                  />
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/unity.png"
                    alt="unity"
                  />
                </div>
              </div>
            </div>
            <div className="container__col-md-6 container__col-xs-12">
              <div className="card">
                <h5 className="card__title --flex-center">Campaign</h5>
                <div className="card__content --flex-center">
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/marketo.png"
                    alt="marketo"
                  />
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/adobe-campaign.png"
                    alt="adobe-campaign"
                  />
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/send-grid.png"
                    alt="send-grid"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container__row">
            <div className="container__col-xs-12">
              <div className="card">
                <h5 className="card__title --flex-center">SEARCH</h5>
                <div className="card__content --flex-center">
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/algolia.png"
                    alt="algolia"
                  />
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/google-cloud-search.png"
                    alt="google-cloud-search"
                  />
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/solr.png"
                    alt="solr"
                  />
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/elastic.png"
                    alt="elastic"
                  />
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/lucid-works.png"
                    alt="lucid-works"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container__row">
            <div className="container__col-md-6 container__col-xs-12">
              <div className="card">
                <h5 className="card__title --flex-center">eCommerce</h5>
                <div className="card__content --flex-center">
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/magento.png"
                    alt="magento"
                  />
                </div>
              </div>
            </div>
            <div className="container__col-md-6 container__col-xs-12">
              <div className="card">
                <h5 className="card__title --flex-center">Project Management</h5>
                <div className="card__content --flex-center">
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/agile.png"
                    alt="agile"
                  />
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/safe-certified.png"
                    alt="safe-certified"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container__row">
            <div className="container__col-xs-12">
              <div className="card">
                <h5 className="card__title --flex-center">UX Design</h5>
                <div className="card__content --flex-center">
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/wireframing.png"
                    alt="wireframing"
                  />
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/prototyping.png"
                    alt="prototyping"
                  />
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/responsive-design.png"
                    alt="responsive-design"
                  />
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/flows.png"
                    alt="flows"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container__row">
            <div className="container__col-md-6 container__col-xs-12">
              <div className="card">
                <h5 className="card__title --flex-center">UX Tools</h5>
                <div className="card__content --flex-center">
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/figma.png"
                    alt="figma"
                  />
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/adobe-xd.png"
                    alt="adobe-xd"
                  />
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/balsamiq.png"
                    alt="balsamiq"
                  />
                </div>
              </div>
            </div>
            <div className="container__col-md-6 container__col-xs-12">
              <div className="card">
                <h5 className="card__title --flex-center">
                  Authentication Providers:{" "}
                </h5>
                <div className="card__content --flex-center">
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/fb-gp-li-t.png"
                    alt="fb-gp-li-t"
                  />
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/auth-o.png"
                    alt="auth-o"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container__row">
            <div className="container__col-xs-12">
              <div className="card">
                <h5 className="card__title --flex-center">Domain-Specific Tool</h5>
                <div className="card__content --flex-center">
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/pendo.png"
                    alt="pendo"
                  />
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/get-id.png"
                    alt="get-id"
                  />
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/criteria.png"
                    alt="criteria"
                  />
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/discourse.png"
                    alt="discourse"
                  />
                  <img
                    className="card__image-inline"
                    src="../../solutions/solutions/technologies-processes/circle.png"
                    alt="circle"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TechProcess;
